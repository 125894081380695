import React, { SyntheticEvent, useCallback, useState } from "react";

import useMeetingState from "../../hooks/meetings/useMeetingState";
import useAttendMeeting from "../../hooks/meetings/useAttendMeeting";
import useCustomerRoles from "../../hooks/useCustomerRoles";
import {
  MeetingAttendConfirmed,
  MeetingLeaveConfirmed,
  MeetingState,
  ParticipantAttendanceType,
  UserRoles,
} from "../../enums";
import Button from "../common/Button";

import UpdateDialog from "../common/dialog/UpdateDialog";
import ConfirmDialog from "../common/dialog/ConfirmDialog";
import AlertDialog from "../common/dialog/AlertDialog";
import MeetingAttendInformation from "./MeetingAttendInformation";
import LoginIcon from "../icons/LoginIcon";
import LogoutIcon from "../icons/LogoutIcon";

import "../../styles/components/meetings/MeetingAttend.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import classNames from "classnames";
import useHandleMeeting from "../../hooks/meetings/useHandleMeeting";
import MeetingWatchInformation from "./MeetingWatchInformation";
import DialogDescriptionDelegateBeforeLeaving from "./DialogDescriptionDelegateBeforeLeaving";
import { Participant } from "../../types";
import useSetVotingEligibility from "../../hooks/meetings/useSetVotingEligibility";
import { EMPLOYEE_PARTICIPANT } from "../../lib/meeting";
import { useRetainVotingEligibilityForParticipantMutation } from "../../api/participantsApi";

export default function MeetingAttend({ title, id }: Props): JSX.Element {
  const {
    customerToken = "",
    facilityObjectId = "",
    meetingId = "",
  } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const meetingIdToAttend = id ? id : "";
  const meetingState = useMeetingState(id);
  const customerRoles = useCustomerRoles();
  const isEmployee = customerRoles.includes(UserRoles.Employee);
  const [attendConfirmed, setAttendConfirmed] = useState(
    MeetingAttendConfirmed.IsToConfirm,
  );
  const [leaveConfirmed, setLeaveConfirmed] = useState(
    MeetingLeaveConfirmed.IsToConfirm,
  );
  const { handleLeave, isAttending, isLeaving, data } = useAttendMeeting(
    meetingState,
    customerRoles,
    id,
  );

  const [delegateParticipant, setDelegateParticipant] = useState<
    Participant | undefined
  >(undefined);
  const [myRelatedParticipant, setMyRelatedParticipant] =
    useState<Participant>(EMPLOYEE_PARTICIPANT);

  const { handleParticipantDelegationSelect } =
    useSetVotingEligibility(myRelatedParticipant);
  const [retainVotingEligibilityForParticipant] =
    useRetainVotingEligibilityForParticipantMutation();
  const meetingAttend = data?.attend;
  const meetingWatch = data?.watch;

  const {
    handleJoinMeeting,
    isParticipationConfirmed,
    setParticipationConfirmed,
  } = useHandleMeeting(id, attendConfirmed);

  const handleCancelConfirmation = useCallback(
    (e: SyntheticEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      setParticipationConfirmed(MeetingAttendConfirmed.IsToConfirm);
      setAttendConfirmed(MeetingAttendConfirmed.IsToConfirm);
      setLeaveConfirmed(MeetingLeaveConfirmed.IsToConfirm);
    },
    [setAttendConfirmed],
  );

  const handleLeaveMeeting = useCallback(
    async (e: SyntheticEvent<HTMLButtonElement>) => {
      if (isEmployee) {
        return;
      }
      e.preventDefault();
      e.stopPropagation();

      const attendance =
        e.currentTarget.id === "dialog-actions-confirm-1"
          ? ParticipantAttendanceType.Abscent
          : ParticipantAttendanceType.Locally;
      switch (leaveConfirmed) {
        case MeetingLeaveConfirmed.IsToConfirm:
          setLeaveConfirmed(MeetingLeaveConfirmed.InConfirmation);
          break;
        case MeetingLeaveConfirmed.InConfirmation:
          setLeaveConfirmed(MeetingLeaveConfirmed.IsToConfirm);
          handleLeave(attendance);
          break;
        default:
          handleLeave(attendance);
      }
      if (delegateParticipant && e.currentTarget.ariaLabel !== "Verlassen") {
        const result = await handleParticipantDelegationSelect(
          delegateParticipant,
          meetingIdToAttend,
        );
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (result.error) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          return result.error;
        }
        navigate("../versammlungen");
      }

      if (!delegateParticipant && e.currentTarget.ariaLabel !== "Verlassen") {
        await retainVotingEligibilityForParticipant({
          customerToken,
          facilityObjectId,
          meetingId: meetingId ? meetingId : meetingIdToAttend,
        });
        navigate("../versammlungen");
      }
    },
    [
      customerRoles,
      leaveConfirmed,
      setLeaveConfirmed,
      handleLeave,
      delegateParticipant,
    ],
  );

  const className = classNames("etv-meeting-attend", {
    "meeting-info-page": pathname.includes(meetingIdToAttend),
  });

  return (
    <div className={className} data-testid="etv-meeting-attend">
      {meetingState === MeetingState.Running && meetingAttend && !isLeaving && (
        <Button
          label="Verlassen"
          grey
          onClick={handleLeaveMeeting}
          leadingIcon={LogoutIcon}
        />
      )}
      {meetingState === MeetingState.Running &&
        !isAttending &&
        !meetingAttend && (
          <Button
            label="Teilnehmen"
            lightblue
            onClick={handleJoinMeeting}
            leadingIcon={LoginIcon}
          />
        )}
      {isAttending && (
        <UpdateDialog
          description={`Teilnahme an ${title} wird gestartet`}
          title="Teilnahme"
        />
      )}
      {isLeaving && (
        <UpdateDialog
          description={`Teilnahme an ${title} wird beendet`}
          title="Teilnahme"
        />
      )}
      {!isEmployee &&
        isParticipationConfirmed === MeetingAttendConfirmed.InConfirmation &&
        meetingState === MeetingState.Running &&
        !meetingWatch && (
          <ConfirmDialog
            description={<MeetingAttendInformation title={title} />}
            title="An der Versammlung teilnehmen"
            onConfirm={handleJoinMeeting}
            confirmLabel="Jetzt teilnehmen"
            onCancel={handleCancelConfirmation}
          />
        )}
      {!isEmployee &&
        isParticipationConfirmed === MeetingAttendConfirmed.InConfirmation &&
        meetingState === MeetingState.Running &&
        meetingWatch && (
          <AlertDialog
            description={
              <MeetingWatchInformation meetingId={meetingIdToAttend} />
            }
            title="Teilnahme an der Versammlung nicht möglich"
            onConfirm={(e) => {
              handleJoinMeeting(e as SyntheticEvent<HTMLButtonElement>);
            }}
            confirmLabel="OK"
          />
        )}
      {!isEmployee &&
        leaveConfirmed === MeetingLeaveConfirmed.InConfirmation && (
          <ConfirmDialog
            description={
              <DialogDescriptionDelegateBeforeLeaving
                meetingIdToAttend={meetingIdToAttend}
                delegateParticipant={delegateParticipant}
                setDelegateParticipant={setDelegateParticipant}
                setMyRelatedParticipant={setMyRelatedParticipant}
              />
            }
            title="Vollmacht erteilen vor verlassen"
            onConfirm={handleLeaveMeeting}
            confirmLabel={
              !delegateParticipant
                ? "Versammlung verlassen"
                : "Vollmacht erteilen und Versammlung verlassen"
            }
            onCancel={handleCancelConfirmation}
          />
        )}
    </div>
  );
}

type Props = {
  title: string;
  id?: string;
};
